import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import {HeartSVG, CommentSVG, PlaneSVG, BookmarkSVG} from './svg';
// import {SelfieReflection, SeeingSurveillance} from './interruptions';
import $ from 'jquery';

var mount = document.getElementById('root');

function changer(theState, value) {
  this.state.theState = value;
}

class Feed extends Component {

  constructor(props) {
      super(props)
      this.state = {
          pulsate: "pulsate"
      }
      this.handleScroll=this.handleScroll.bind(this);
  }

  handleScroll(event) {
    this.setState({pulsate: "hide"});
  };

  render() {
    return (
      <div className={`pageWrap ${this.state.pulsate}`}>
      	<div className="cell-phone-wrapper" onScroll={this.handleScroll.bind(this)}>
          <span className="cell-top">
            <span className="speaker"></span>
          </span>
          <div id="scroller" className="cell-phone-screen-wrapper ready">
            <InstagramPosts />
          </div>
          <span className="cell-bottom">
            <span className="button"></span>
          </span>
        </div>
      </div>
    );
  }
}

export class InstagramPosts extends Component {

  state = {
    posts: [],
    visibility: "hidden"
  }

  componentDidMount() {

    var token = "3951869.da06fb6.5208e2d484c8446d8405b302d84e1e3f";
    var hashtag = "meme";
    var num_photos = 10;
    var smallHash = hashtag.toLowerCase();
    var jsonUrl = "https://www.instagram.com/explore/tags/" + smallHash + "/?__a=1";
    var stateArray = [];

    function shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    axios.get(jsonUrl).then(res => {
      var length = res.data.graphql.hashtag.edge_hashtag_to_media.edges.length;
      var max = 100;
      var count = length > max ? max : length;
      const results = res.data.graphql.hashtag.edge_hashtag_to_media.edges;

      results.forEach(function(result, index){
        if(index+1 <= count) {
          var c = result.node;
          var per = Math.round(count * .25);

          if(index === per) {
            c.interrupt = true;
          }else{
            c.interrupt = false;
          }

          stateArray.push(c);
        }
      });

      this.setState({
        posts: stateArray,
        visibility: "visible"
      });
    });

    // changer("loading", "loaded");

  }

  render() {
    return (
      <ul id="posts" className={this.state.visibility} >
      {this.state.posts.map(function(post,i){

        var shortcode = post.shortcode;
        var type = post.__typename;
        var img = post.display_url;
        var alt = post.accessibility_caption;

        // console.log(post);

        if(post.interrupt === true){
          return <Interruptor key={"interruption"} />
        }else{
          return <SinglePost 
              key={shortcode} 
              type={type}
              image={img}
              alt={alt}
            />
        }
      })}
      </ul>
    )
  }
}

class SinglePost extends Component {
  render() {
    return (
      <li key={this.key}>
      <div className="feed-item image-item" className={`feed-item image-item ${this.props.type}`} >
        <div className="user-bar">
          <div className="user-icon"><span></span></div>
          <div className="user-handle">someone_u_follow</div>
          <div className="some-circles">• • •</div>
        </div>
        <div className="post-image"><img src={this.props.image} alt={this.props.alt ? this.props.alt : "A distracting meme post"} /></div>
        <div className="icons-row">
          <span className="like"><HeartSVG /></span>
          <span className="comment"><CommentSVG /></span>
          <span className="dm"><PlaneSVG /></span>
          <span className="save"><BookmarkSVG /></span>
        </div>
      </div>
    </li>
    )
  }
}
 
class Interruptor extends Component {

  constructor(props) {
      super(props)
      this.state = {
          theHeight: document.getElementsByClassName('cell-phone-screen-wrapper')[0].clientHeight,
      }
  }

  componentDidMount() {
    $('#scroller').scroll(function(){
      
        var screenTop = $('#scroller').offset().top;
        var screenHeight = $('#scroller').height();

        var screenPoint = screenTop + screenHeight;

        var interruptorTop;

        if($('#interruptor.active').length) {
          interruptorTop = $('#interruptor.active').offset().top; 
          if(interruptorTop && interruptorTop < screenPoint) {
            var contactTopPosition = $("#interruptor.active").position().top;
            $("#scroller").animate({scrollTop: contactTopPosition});
          }
        }
    });
  }

  render() {

    var hashtag = window.location.hash;
    var interruption;
    var the_interruptor = document.getElementById('interruptor');

    
    if(hashtag === "#surveillance") {
      interruption = (<SeeingSurveillance />);
    }else{
      interruption = (<SelfieReflection />);
    }

    window.addEventListener('resize', function(event){
      this.setState = {
        theHeight: document.getElementsByClassName('cell-phone-screen-wrapper')[0].clientHeight
      }
    });

    const the_height = this.state.theHeight;

    return (
      <li id="interruptor" className="interruptor active" style={{height: the_height}} >
        {interruption}
      </li>
    );
  }
}

class NavButton extends Component {

  clicker() {
    stepChange(this.props.next);
  }

  render() {
    return (
      <button className={this.props.classer} onClick={this.clicker.bind(this)}>{this.props.text}</button>
    );
  }

}

function stepChange(nextStep) {
    this.setState({
      step: nextStep
    });
}

function exerciseChange() {
  const ex = Number(this.state.exStep) + 1;
  this.setState({
    exStep: ex
  });
}

class InterruptionStart extends Component {
  render() {
    return (
      <div className="interruption-wrap">
        <p>Pardon this interruption. We want to remind you that it is important to use technology responsibly.</p>
        <p>Just to make sure, we should go through an exercise together.</p>
      </div>
    );
  }
}

class StepDesc extends Component {
  render() {
    return (
      <div className="desc-step">
        <h2>{this.props.title}</h2>
        <img src={this.props.image} />
        <p>{this.props.description}</p>
      </div>
    );
  }
}

var amountOfTime = 15;

class ExerciseText extends React.Component {

  constructor(props) {
      super(props)
      this.state = {
          exStep: 1,
          currentCount: amountOfTime
      }
      exerciseChange = exerciseChange.bind(this);
  }

  render() {

    const xStep = this.state.exStep;
    var xStepMarkup;

    if(this.state.exStep === 1){
      xStepMarkup = ( <span className="exStep-text-1"><span className="exStepText">{this.props.exStepText1}</span><Clock /></span> );
    }else if(this.state.exStep === 2) {
      xStepMarkup = ( <span className="exStep-text-2"><span className="exStepText">{this.props.exStepText2}</span><Clock /></span> );
    }else if(this.state.exStep === 3) {
      xStepMarkup = ( <span className="exStep-text-3"><span className="exStepText">{this.props.exStepText3}</span><Clock /></span> );
    }else if(this.state.exStep === 4) {
      xStepMarkup = ( <span className="exStep-text-4"><span className="exStepText">{this.props.exStepText4}</span><Clock /></span> );
    }else if(this.state.exStep === 5) {
      xStepMarkup = ( <span className="exStep-text-5"><span className="exStepText">{this.props.exStepText5}</span><Clock /></span> );
    }else if(this.state.exStep === 6) {
      xStepMarkup = ( <span className="exStep-text-6"><span className="exStepText">{this.props.exStepText6}</span><Clock /></span> );
    }else if(this.state.exStep === 7) {
      xStepMarkup = ( <span className="exStep-text-7"><span className="exStepText">{this.props.exStepText7}</span><Clock /></span> );
    }else if(this.state.exStep === 8 && this.props.exStepText8 ) {
      xStepMarkup = ( <span className="exStep-text-8"><span className="exStepText">{this.props.exStepText8}</span><Clock /></span> );
    }else if(this.state.exStep === 9 && this.props.exStepText9 ) {
      xStepMarkup = ( <span className="exStep-text-9"><span className="exStepText">{this.props.exStepText9}</span><Clock /></span> );
    }else if(this.state.exStep === 10 && this.props.exStepText10 ) {
      xStepMarkup = ( <span className="exStep-text-10"><span className="exStepText">{this.props.exStepText10}</span><Clock /></span> );
    }else if(this.state.exStep === 11 && this.props.exStepText11 ) {
      xStepMarkup = ( <span className="exStep-text-10"><span className="exStepText">{this.props.exStepText11}</span><Clock /></span> );
    }else if(this.state.exStep === 12 && this.props.exStepText12 ) {
      xStepMarkup = ( <span className="exStep-text-10"><span className="exStepText">{this.props.exStepText12}</span><Clock /></span> );
    }else{
      xStepMarkup = ( <span><Stepper /></span> );
    }

    return (
      <div className="exercise-steps">
        <div className={`exStep-${this.state.exStep}`}>
          {xStepMarkup}
        </div>
      </div>
    );
  }
}

 
class Stepper extends Component {

  componentDidMount() {
    stepChange('completion');
  }

  render() {
    console.log('stepper');
    return (
      <div>
        
      </div>
    );
  }
}

var timer = amountOfTime;

class Clock extends Component {

  constructor(props){
    super(props);
    this.state = {currentCount: timer}

  }

  timer() {
    this.setState({
      currentCount: this.state.currentCount - 1
    });

    if(this.state.currentCount < 0) { 

      this.setState({
        currentCount: timer
      });
      exerciseChange();
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.timer.bind(this), 1000);
  }

  componentWillUnmount(){
     clearInterval(this.intervalId);
  }

  render() {
    return(
      <div className="countdown">{this.state.currentCount}</div>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// SELFIE REFLECTION
///////////////////////////////////////////////////////////////////////////////////////////////////

export class SelfieReflection extends Component {

  constructor(props) {
      super(props)
      this.state = {
          step: "start"
      }
      stepChange=stepChange.bind(this);
  }

  componentDidUpdate() {
      var video = document.getElementById('video');
      if(this.state.step === 'camera'){
        if(navigator.mediaDevices && navigator.getUserMedia) {
          // Not adding `{ audio: true }` since we only want video now
          navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
              // video.src = window.URL.createObjectURL(stream);
              video.srcObject = stream;
              video.play();
          });
        }
      }
  }

  render() {

    var stepper;

    if(this.state.step === "start") {
      stepper = (
        <div className="int-wrap">
          <InterruptionStart />
          <NavButton classer="continue" text="CONTINUE" next="description" />
        </div>
      );
    }else if(this.state.step === "description"){
      var img = '/images/selfie_reflection.png';
      stepper = (
        <div className="int-wrap">
          <StepDesc title="This is Selfie Reflection" image={img} description="With all of the digital distractions out there, it's easy to forget about number 1. I'm looking at you. You should be too." />
          <NavButton classer="continue" text="CONTINUE" next="camera_permission" />
        </div>
      );
    }else if(this.state.step === "camera_permission"){
      stepper = (
        <div className="int-wrap">
          <p>Let's take a selfie!</p>
          <p>May we access your phone's camera?</p>
          <NavButton classer="continue" text="YES, CONTINUE" next="camera" />
        </div>
      );
    }else if(this.state.step === "camera"){
      stepper = (
        <div className="int-wrap video">
          <div className="video-wrap">
            <video id="video" width="640" height="480" autoPlay muted>
              <source/>
            </video>
            <ExerciseText 
              exStepText1="Hey! Nice to see your face! I'm going to walk you through an exercise as soon as the counter reaches 0."
              exStepText2="Observe your skin. Would you say it looks healthy?"
              exStepText3="If you have hair, take note of it. Is it combed or messy?"
              exStepText4="How about your eyes? Do they look clear?"
              exStepText5="How about your mouth? Are you dehydrated or do you need to brush your teeth?"
              exStepText6="Now take a deep breath through your nose... Hold it."
              exStepText7="Exhale through your mouth."
            />
          </div>
        </div>
      );
    }else if(this.state.step === "completion"){
      stepper = (
        <div className="int-wrap">
          <p>Alright, looking good!</p>
          <p>You can continue with your regularly scheduled instagram feed.</p>
          <NavButton classer="continue" text="BACK TO MY FEED" next="close" />
        </div>
      );
    }
    else if(this.state.step === "close") {
        $('#interruptor').removeClass('active').height(0);
        $('#scroller').removeClass('ready');
    }
    return (
      <div>
        {stepper}
      </div>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// SEEING SURVEILLANCE
///////////////////////////////////////////////////////////////////////////////////////////////////

export class SeeingSurveillance extends Component {

  constructor(props) {
      super(props)
      this.state = {
          step: "start"
      }
      stepChange=stepChange.bind(this);
  }

  render() {

    var stepper;
    var d = new Date();
    var hours = d.getUTCHours() + 8;
    var minutes = d.getUTCMinutes();

    if(minutes < 10){
      minutes = "0" + minutes;
    }

    var format = hours + ":" + minutes;

    if(this.state.step === "start") {
      stepper = (
        <div className="int-wrap">
          <InterruptionStart />
          <NavButton classer="continue" text="CONTINUE" next="description" />
        </div>
      );
    }else if(this.state.step === "description"){
      var img = '/images/seeing_surveillance.png';
      stepper = (
        <div className="int-wrap">
          <StepDesc title="This is Seeing Surveillance" image={img} description="With so many digital distractions, it's difficult to stay in the moment. There are thousands of live surveillance feeds in the world. We should check one out." />
          <NavButton classer="continue" text="CONTINUE" next="camera_permission" />
        </div>
      );
    }else if(this.state.step === "camera_permission"){
      stepper = (
        <div className="int-wrap">
          <p>Let's go to the Xinzhuang District of Taipei in Taiwan where the local time is {format}</p>
          <NavButton classer="continue" text="YES, CONTINUE" next="camera" />
        </div>
      );
    }else if(this.state.step === "camera"){
      stepper = (
        <div className="int-wrap video">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/1y5dcfnv-Ss?rel=0&autoplay=1&mute=1&showinfo=0&controls=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
            <ExerciseText 
              exStepText1="I want you to observe the live feed as I ask you a few questions."
              exStepText2="Think about the economics of the area. Is it a wealthy neighborhood or a poor neighborhood?"
              exStepText3="What do you think happens in this area? Is it commercial or residential?"
              exStepText4="What is the architectural style of the neighborhood?"
              exStepText5="Is it urban or suburban?"
              exStepText6="Do you see any humans? If so, how many?"
              exStepText7="Do you see any cars? If so, how many?"
              exStepText8="How many plants, if any, are visible?"
              exStepText9="What is something that is currently happening in the feed? e.g. The street is being cleaned."
              exStepText10="Now I want you to imagine you are here in person. Is it noisy or quiet?"
              exStepText11="Is it hot or cold?"
              exStepText12="What does it smell like?"
            />
        </div>
      );
      
    }else if(this.state.step === "completion"){

      stepper = (
        <div className="int-wrap">
          <p>Alright, time to return home.</p>
          <p>You can continue with your regularly scheduled instagram feed.</p>
          <NavButton classer="continue" text="BACK TO MY FEED" next="close" />
        </div>
      );
    }else if(this.state.step === "close") {
        $('#interruptor').removeClass('active').height(0);
        $('#scroller').removeClass('ready');
    }

    return (
      <div>
        {stepper}
      </div>
    );
  }
}

ReactDOM.render(<Feed />, mount);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
